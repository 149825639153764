import { createSlice } from '@reduxjs/toolkit';

export const DrawerMainLayoutSlice = createSlice({
  name: 'DrawerMainLayoutReducer',
  initialState: [{}],
  reducers: {
    DrawerMainLayoutOpen(state, action) {
      return { ...state, isOpen: true };
    },
    DrawerMainLayoutClose(state) {
        return { ...state, isOpen: false };
    }
  },
});

export const { DrawerMainLayoutOpen, DrawerMainLayoutClose } = DrawerMainLayoutSlice.actions;
export default DrawerMainLayoutSlice.reducer;
