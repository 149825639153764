export const routeSso = {

    // DASHBOARD
    api_sso_dashboard_get_right: '/api/sso/dashboard/get-right',

    // AUTHENTIFICATION
    api_sso_login: '/api/sso/login',
    api_sso_signup_complete: '/api/sso/signup-complete',
    api_sso_verify_token: '/api/sso/verify-token',
    api_sso_check_validity_code: '/api/sso/check-validity-code',
    api_sso_password_reset_send: '/api/sso/password-reset/send',
    api_sso_password_reset_change: '/api/sso/password-reset/change',

    // ADMINISTRATOR
    api_sso_administrator_user_list: '/api/sso/administrator/user/list',
    api_sso_administrator_user_add: '/api/sso/administrator/user/add',
    api_sso_administrator_user_edit: '/api/sso/administrator/user/{uuid}',

    api_sso_administrator_role_list: '/api/sso/administrator/role/list',
    api_sso_administrator_role_add: '/api/sso/administrator/role/add',
    api_sso_administrator_role_edit: '/api/sso/administrator/role/{id}',

    // SELECT
    api_sso_select_role: '/api/sso/select/role',

    // AUTOCOMPLETE
    api_sso_auto_complete_users : '/api/sso/auto-complete/users'
};