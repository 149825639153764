export const spacing = {
    "2px": "2px",
    0.5: "4px",
    0.75: "6px",
    1: "8px",
    1.25: "10px",
    1.5: "12px",
    1.75: "14px",
    2: "16px",
    2.5: "20px",
    3: "24px",
    4: "32px",
    5: "40px",
    6: "48px",
    7: "56px",
    8: "64px",
    9: "72px",
    10: "80px",
    11: "88px",
    12: "96px",
    13: "104px",
    14: "112px",
    15: "120px",
    16: "128px",
    17: "136px",
    18: "144px",
    19: "152px",
    20: "160px",
};
