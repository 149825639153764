export const MuiDrawer = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            height: ownerState.variant === "temporary" ? "max-content" : "100%",
            backgroundColor: theme.palette.common.white,
            flexShrink: 0,
            whiteSpace: "nowrap",
            boxSizing: "border-box",
            borderRight: `1px solid ${theme.palette.action.selected}`,
            zIndex: 1,
            "& .MuiDrawer-paperAnchorDockedLeft": {
                border: 0,
            },
            "& .MuiDrawer-paper": {
                position: "relative",
                height: "100%",
                width: "100%",
                padding: `0px  ${theme.spacingTheme["2"]} ${theme.spacingTheme["5"]} ${theme.spacingTheme["2"]}`,
                boxSizing: "border-box",
                overflowY: "visible",
            },
            "& .sidebarBtn": {
                position: "absolute",
                top: "27px",
                right: "-13px",
                width: "26px",
                height: "26px",
                zIndex: 9999,
                backgroundColor: theme.palette.common.white,
                border: `1px solid ${theme.palette.action.selected}`,
                boxShadow: theme.shadows[1],
                "&:hover": {
                    backgroundColor: theme.palette.common.white,
                },
                "& svg": {
                    width: "16px",
                    height: "16px",
                },
            },
            "& .MuiCollapse-wrapperInner": {
                display: "flex",
                flexDirection: "column",
                gap: theme.spacingTheme["0.5"],
                paddingTop: theme.spacingTheme["0.5"],
            },
        }),
    },
};

export const MuiListItemButton = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            minHeight: ownerState.className === "collapse-listItemButton" ? "36px" : "44px",
            padding: `${theme.spacingTheme["0.5"]} ${theme.spacingTheme["1"]}`,
            borderRadius: theme.spacingTheme["1"],
            gap: theme.spacingTheme["1.5"],
            ...listItemBtnPropsByState(ownerState, theme),
            "& .MuiTypography-root": {
                overflowX: "hidden",
                textOverflow: "ellipsis",
                fontSize: "14px",
                fontWeight: theme.typography.fontWeightSemiBold,
            },
        }),
    },
};

const listItemBtnPropsByState = (state, theme) => {
    switch (state.className) {
        case "collapse-listItemButton":
            return collapseItemBtnProps(state, theme);
        case "popover-listItemButton":
            return popoverItemBtnProps(state, theme);
        default:
            return defaultItemBtnProps(state, theme);
    }
};

const defaultItemBtnProps = (state, theme) => {
    const commonProps = {
        "&:hover": {
            color: theme.palette.primary.main + " !important",
            backgroundColor: theme.palette.action.selectedListItem + " !important",
            "& .MuiListItemIcon-root": {
                color: theme.palette.primary.main,
            },
        },
    };

    if (state.selected) {
        return {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.action.selectedListItem,
            "& .MuiListItemIcon-root": {
                color: theme.palette.primary.main,
            },
            ...commonProps,
        };
    } else {
        return {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.common.white,
            "& .MuiListItemIcon-root": {
                color: theme.palette.text.secondary,
            },
            ...commonProps,
        };
    }
};

const collapseItemBtnProps = (state, theme) => {
    const commonProps = {
        marginLeft: "68px",
        "&:hover": {
            color: theme.palette.grey["800"] + " !important",
            backgroundColor: theme.palette.action.selected + " !important",
            "& .MuiListItemIcon-root": {
                color: theme.palette.grey["800"],
            },
        },
    };

    if (state.selected) {
        return {
            color: theme.palette.grey["800"],
            backgroundColor: theme.palette.action.selected + " !important",
            "& .MuiListItemIcon-root": {
                color: theme.palette.action.selected,
            },
            ...commonProps,
        };
    } else {
        return {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.common.white,
            "& .MuiListItemIcon-root": {
                color: theme.palette.text.secondary,
            },
            ...commonProps,
        };
    }
};

const popoverItemBtnProps = (state, theme) => {
    const commonProps = {
        borderRadius: "0 !important",
        padding: `${theme.spacingTheme["0.5"]} ${theme.spacingTheme["2"]} !important`,
        "&:hover": {
            color: theme.palette.grey["800"] + " !important",
            backgroundColor: theme.palette.action.selected + " !important",
            "& .MuiListItemIcon-root": {
                color: theme.palette.grey["800"],
            },
        },
    };

    if (state.selected) {
        return {
            color: theme.palette.grey["800"],
            backgroundColor: theme.palette.action.selected + " !important",
            "& .MuiListItemIcon-root": {
                color: theme.palette.action.selected,
            },
            ...commonProps,
        };
    } else {
        return {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.common.white,
            "& .MuiListItemIcon-root": {
                color: theme.palette.text.secondary,
            },
            ...commonProps,
        };
    }
};
