import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { useDispatch, useSelector } from 'react-redux';
import { SnackbarClose, SnackbarOpen } from './SnackBarSlice';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackBarComponent() {
  const dispatch = useDispatch();
  const selectSnackBarPayload = useSelector((state) => state.SnackBarReducer);
  const [state, setState] = useState({
    isOpen: false,
    variant: '',
    duration: 3000,
    text: ''
  });

  useEffect(() => {
    const payload = selectSnackBarPayload[selectSnackBarPayload.length - 1];

    setState({
      isOpen: payload.isOpen ? payload.isOpen : false,
      variant: payload.variant,
      duration: payload.duration,
      text: payload.text
    });

    if (payload.isOpen) {
      const timer = setTimeout(() => {
        dispatch(SnackbarClose());
      }, payload.duration);

      return () => clearTimeout(timer);
    }
  }, [selectSnackBarPayload, dispatch]);

  return (
    <Snackbar open={state.isOpen} TransitionComponent={Slide} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
      <Alert severity={state.variant} sx={{ width: '100%' }}>
        {state.text}
      </Alert>
    </Snackbar>
  );
}
