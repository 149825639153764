/****************** MUIDataTable ******************/
export const MUIDataTable = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            "& .MuiTableCell-head": {
                backgroundColor: theme.palette.grey[200],
                color: theme.palette.text.secondary,
            },
        }),
        paper: {
            boxShadow: "none",
        },
    },
};

export const MUIDataTableToolbar = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({}),
        actions: ({ ownerState, theme }) => ({
            width: "100%",
            textAlign: "start",
            "& .MuiButtonBase-root:hover": {
                color: theme.palette.primary.main,
            },
            "& .MuiButtonBase-root.active": {
                color: theme.palette.primary.main,
            },
        }),
    },
};

export const MUIDataTableBodyRow = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            borderBottom: `1px dashed ${theme.palette.action.selected}`,
        }),
    },
};

export const MuiTableFooter = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({}),
    },
};

/****************** Table Container ******************/
export const MuiTable = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            "& .MuiTableHead-root": {
                backgroundColor: theme.palette.grey[200],
                color: theme.palette.text.secondary,
            },
            "& .MuiTableBody-root .MuiTableRow-root": {
                borderBottom: `1px dashed ${theme.palette.action.selected}`,
            },
            "& .MuiTableCell-root": {
                color: theme.palette.text.secondary,
            },

            "& .MuiTableRow-root.error .MuiTableCell-root": {
                color: theme.palette.warning.main,
            },

            "& .MuiTableRow-root.error .MuiTableCell-root .MuiIconButton-root": {
                color: theme.palette.warning.main,
            },
        }),
    },
};
