let routingFunctions = {
    getPathDefault: null,
    getRoutes: null,
    updateRouting: null,
    getRouteByPathname: null,
    getRoutePathname: null,
};

export async function loadRoutingFunctions(subdomain) {
    let routingModule;
    switch (subdomain) {
        case 'sso':
            routingModule = await import('./SsoRouteHandler');
            document.title = 'Auth SSO';
            break;
        case 'app':
            routingModule = await import('./AppRouteHandler');
            document.title = 'MedicActiv';
            break;
        case 'bo':
            routingModule = await import('./BoRouteHandler');
            document.title = 'BackOffice MedicActiv';
            break;
        default:
            routingModule = null;
            break;
    }

    if (routingModule) {
        routingFunctions = {
            ...routingModule
        };
    }

    return routingFunctions;
}

export function getRoutingFunctions() {
    return routingFunctions;
}
