import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SnackBarComponent from "./Reducer/SnackBarReducer/SnackBarComponent";
import { ScreenChange } from "./Reducer/ScreenReducer/ScreenSlice";
import momentFR from "moment-locale-fr";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ApiHandler from "./Handler/ApiHandler";
import { AuthenticationLogin } from "./Reducer/AuthenticationReducer/AuthentificationSlice";
import { IntlProvider } from "react-intl";
import { flatten } from "flat";
import * as tradEn from "./lang/en.json";
import * as tradFr from "./lang/fr.json";
import { getRoutingFunctions, loadRoutingFunctions } from "./Handler/RouteHandler/RoutingFunctionHandler";
import { ThemeProvider } from "@mui/material/styles";
import DefaultTheme from "./Theme/DefaultTheme";

//------ Locale --------//
function loadLocaleData(locale) {
    switch (locale) {
        case "fr":
            return flatten(tradFr);
        default:
            return flatten(tradEn);
    }
}

//------ SubDomain --------//
function getSubdomain() {
    const hostname = window.location.hostname;
    const parts = hostname.split(".");

    if (parts.length > 2) {
        if (hostname.includes("dev-")) {
            parts[0] = parts[0].replace(/dev-|-v2/g, "");
            return parts[0];
        } else if (hostname.includes("my-preprod")) {
            if (parts[0].startsWith("bo-")) {
                return "bo";
            } else if (parts[0].startsWith("sso-")) {
                return "sso";
            } else if (parts[0] === "my-preprod") {
                return "app";
            }
        } else if (hostname.includes("my.simforhealth.com")) {
            if (parts[0].startsWith("bo-")) {
                return "bo";
            } else if (parts[0].startsWith("sso-")) {
                return "sso";
            } else if (parts[0] === "my") {
                return "app";
            }
        }
    }
    return null;
}

//------ Dispatch --------//
export let dispatch;

//------- Routes Nav --------//
export let navRoutes;

//------ Moment --------//
moment.updateLocale("fr", momentFR);
moment.locale("fr");

function App() {
    dispatch = useDispatch();
    const [routes, setRoutes] = React.useState(false);
    const [routeDefault, setRouteDefault] = React.useState("");
    const [isReady, setReady] = React.useState(false);
    const [trad, setTrad] = React.useState(flatten(tradEn));
    const subdomain = getSubdomain();
    const authenticationReducer = useSelector((state) => state.AuthenticationReducer);

    navRoutes = routes;
    //---------- Locale --------------//
    React.useEffect(() => {
        setTrad(loadLocaleData("fr"));
    }, []);

    //---------- Screen --------------//
    const resize = () => {
        let screen = "";

        if (window.innerWidth < 576) screen = "XS";
        if (window.innerWidth >= 576 && window.innerWidth < 768) screen = "SM";
        if (window.innerWidth >= 768 && window.innerWidth < 992) screen = "MD";
        if (window.innerWidth >= 992 && window.innerWidth < 1200) screen = "LG";
        if (window.innerWidth >= 1200) screen = "XL";
        dispatch(
            ScreenChange({
                screen: screen,
                size: window.innerWidth,
                orientation: window.screen.orientation && window.screen.orientation.angle === 90,
            }),
        );
    };

    React.useEffect(() => {
        window.addEventListener("resize", resize);
        window.addEventListener("orientationchange", resize);
        resize();

        return () => {
            window.removeEventListener("resize", resize);
            window.removeEventListener("orientationchange", resize);
        };
    }, []);

    //---------- Routing --------------//

    React.useEffect(() => {
        async function loadRouting() {
            await loadRoutingFunctions(subdomain);
            const { getPathDefault, getRoutes, updateRouting } = getRoutingFunctions();
            let info = {};
            ApiHandler.get({ route: "api_default" }, (response) => {
                if (response && response.status === 200 && response.data.authenticated) {
                    let user = response.data.user;

                    info = {
                        uuid: user.uuid,
                        email: user.email,
                        roles: user.roles,
                        subdomain: response.data.subdomain,
                        lastname: user.lastname,
                        firstname: user.firstname,
                        spaces: user.spaces,
                        selectedSpace: authenticationReducer.selectedSpace
                            ? authenticationReducer.selectedSpace
                            : user.spaces[0].uuid,
                        hasBo: user.hasBo,
                    };
                    dispatch(AuthenticationLogin(info));
                }
                updateRouting(info);
                setRoutes(getRoutes());
                setRouteDefault(getPathDefault());
                setReady(true);
            });
        }

        loadRouting();
    }, [subdomain, authenticationReducer.selectedSpace]);

    if (isReady && routes) {
        return (
            <ThemeProvider theme={DefaultTheme}>
                <IntlProvider locale="fr" defaultLocale="en" messages={trad}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <BrowserRouter>
                            <Routes>
                                {Object.keys(routes).map((key) => {
                                    const route = routes[key];
                                    return (
                                        <Route
                                            key={"route_" + key}
                                            path={route.path}
                                            element={<route.layout view={route.view} />}
                                        />
                                    );
                                })}
                                <Route path="*" element={<Navigate to={routeDefault} replace />} />
                            </Routes>
                            <SnackBarComponent />
                        </BrowserRouter>
                    </LocalizationProvider>
                </IntlProvider>
            </ThemeProvider>
        );
    }
}

export default App;
