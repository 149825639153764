export const MuiTabs = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            backgroundColor: theme.palette.common.white,
            boxShadow: "unset",
            "& .MuiTabs-flexContainer": {
                gap: ownerState.islarge ? theme.spacingTheme["5"] : "",
            },
            "& .MuiTabs-indicator": {
                backgroundColor: theme.palette.grey[800],
            },
            "& .MuiButtonBase-root": {
                fontWeight: theme.typography.fontWeightMedium,
                '&[aria-selected="true"]': {
                    color: theme.palette.grey[800],
                },
            },
        }),
    },
};
