export const MuiFormControl = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            width: "100%",
            ...colorsByState(ownerState, theme),
        }),
    },
};

export const MuiTextField = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            width: "100%",
            ...colorsByState(ownerState, theme),
            "& .MuiFormHelperText-root": {
                display: "inline-flex",
                alignItems: "center",
                gap: theme.spacingTheme["2px"],
            },
        }),
    },
};

export const MuiSelect = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            width: "100%",
            ...colorsByState(ownerState, theme),
        }),
    },
};

export const MuiPickers = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            width: "100%",
            ...colorsByState(ownerState, theme),
        }),
    },
};

const colorsByState = (state, theme) => {
    let stateStr = state.disabled
        ? "disabled"
        : state.focused
          ? "focused"
          : state.error
            ? "error"
            : state.successfield
              ? "success"
              : "";

    switch (stateStr) {
        case "disabled":
            return {
                "& fieldset": {
                    backgroundColor: theme.palette.action.disabledBackground + " !important",
                    border: "none",
                },
                "& .MuiFormHelperText-root": {
                    color: theme.palette.text.secondary + " !important",
                },
                "& label": {
                    color: theme.palette.text.secondary + " !important",
                },
            };
        case "focused":
            return {
                "& fieldset": {
                    borderColor: theme.palette.secondary.main + " !important",
                },
                "& .MuiFormHelperText-root": {
                    color: theme.palette.secondary.main + " !important",
                },
                "& label": {
                    color: theme.palette.secondary.main + " !important",
                },
            };
        case "error":
            return {
                "& fieldset": {
                    borderColor: theme.palette.error.main + " !important",
                },
                "& .MuiFormHelperText-root": {
                    color: theme.palette.error.main + " !important",
                },
                "& label": {
                    color: theme.palette.error.main + " !important",
                },
            };
        case "success":
            return {
                "& fieldset": {
                    borderColor: theme.palette.success.main + " !important",
                },
                "& .MuiFormHelperText-root": {
                    color: theme.palette.success.main + " !important",
                },
                "& label": {
                    color: theme.palette.success.main + " !important",
                },
            };
    }
};
