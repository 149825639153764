import { createSlice } from '@reduxjs/toolkit';

const TableSlice = createSlice({
  name: 'TableReducer',
  initialState: {
    id: '',
    withCache: false
  },
  reducers: {
    TableReload: {
      reducer(state, action) {
        state.id = action.payload.id;
        state.withCache = action.payload.withCache;
      },
      prepare(id, withCache = false) {
        return { payload: { id, withCache } };
      },
    },
    ResetState: (state) => {
      state.id = '';
      state.withCache = false;
    },
  },
});

export const { TableReload, ResetState } = TableSlice.actions;

export default TableSlice.reducer;
