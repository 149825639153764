export const routeApp = {
    // DASHBOARD
    api_app_dashboard_space_get: "/api/app/dashboard/space/{uuid}",

    // TRAINING
    api_app_training_list: "/api/app/training/list",
    api_app_training_get: "/api/app/training/{id}",
    api_app_training_course_get: "/api/app/training/{id}/course/{course}",

    // USER MANAGER
    api_app_user_manager_app_space_users_list: "/api/app/user-manager/app-space/{slug}/users/list",
    api_app_user_manager_app_space_users_add: "/api/app/user-manager/app-space/{slug}/users/add",
    api_app_user_manager_app_space_users_delete: "/api/app/user-manager/app-space/{slug}/users/delete",
    api_app_user_manager_app_space_user_edit: "/api/app/user-manager/app-space/{slug}/user/{uuid}",

    api_app_user_manager_group_list: "/api/app/user-manager/group/list",
    api_app_user_manager_group_get: "/api/app/user-manager/group/{uuid}",
    api_app_user_manager_group_add: "/api/app/user-manager/group/add",
    api_app_user_manager_group_edit: "/api/app/user-manager/group/{uuid}",
    api_app_user_manager_group_check_add: "/api/app/user-manager/group/{uuid}/check-add",
    api_app_user_manager_group_delete: "/api/app/user-manager/group/{uuid}",
    api_app_user_manager_group_check_delete: "/api/app/user-manager/group/{uuid}/check-delete",
    api_app_user_manager_group_user_delete: "/api/app/user-manager/group/user/delete",
    api_app_user_manager_group_license_get: "/api/app/user-manager/group/{uuid}/license/get",
    api_app_user_manager_group_user_license_deactivate: "/api/app/user-manager/group/user/license/{id}/deactivate",

    // SESSIONS
    app_learner_session_training_manager_list: "/api/app/learner/session-training-manager/list",
    app_learner_session_training_manager_add: "/api/app/learner/session-training-manager/add",
    app_learner_session_training_manager_check_add: "/api/app/learner/session-training-manager/checkAdd",
    app_learner_session_training_manager_archived: "/api/app/learner/session-training-manager/{id}/archived",

    app_learner_session_training_manager_license_packages_list:
        "/api/app/learner/session-training-manager/license-packages/list",
    app_learner_session_training_manager_courses_list: "/api/app/learner/session-training-manager/courses/list",
    app_learner_session_training_manager_check_availability_license_package:
        "/api/app/learner/session-training-manager/check-availability/license-package",

    // COURSES
    api_app_course_list: "/api/app/course/list",

    // SETTING
    api_app_setting_my_account_list: "/api/app/setting/my-account/list",
    api_app_setting_my_account_info_edit: "/api/app/setting/my-account/info/{uuid}",
    api_app_setting_my_account_info_password_change: "/api/app/setting/my-account/info/{uuid}/password/change",

    // SELECT
    api_app_select_country: "/api/app/select/country",
    api_app_select_hourFormat: "/api/app/select/hour-format",
    api_app_select_language: "/api/app/select/language",
    api_app_select_professionalStatus: "/api/app/select/professional-status",
    api_app_select_role: "/api/app/select/role",
    api_app_select_spaces: "/api/app/select/spaces",
    api_app_select_space_groups: "/api/app/select/space/{slug}/groups",
    api_app_select_space_courses: "/api/app/select/space/{slug}/courses",
    api_app_select_courses: "/api/app/select/courses",
    api_app_select_trainings: "/api/app/select/trainings",

    // AUTOCOMPLETE
    api_app_auto_complete_users: "/api/app/auto-complete/users",
    api_app_auto_complete_users_emails: "/api/app/auto-complete/users/only-email",
    api_app_auto_complete_spaces: "/api/app/auto-complete/spaces",
    api_app_auto_complete_users_space: "/api/app/auto-complete/users/space",
    api_app_auto_complete_tag: "/api/app/auto-complete/tag",

    // FILE HANDLER
    api_app_file_handler_parse_csv_email: "/api/app/file-handler/parse/csv/email",
};
