export const MuiBreadcrumbs = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            width: "100%",
            marginBottom: theme.spacingTheme["2"],
            "& > ol": {
                gap: theme.spacingTheme["2"],
                rowGap: theme.spacingTheme["0.5"],
            },
            "& .MuiBreadcrumbs-separator": {
                width: "4px",
                margin: 0,
            },
            "& p": {
                margin: 0,
                fontSize: theme.typography.body2.fontSize,
                fontWeight: theme.typography.body2.fontWeight,
                color: theme.palette.grey["500"],
            },
            "& a": {
                margin: 0,
                fontSize: theme.typography.body2.fontSize,
                fontWeight: theme.typography.body2.fontWeight,
                color: theme.palette.grey["800"],
            },
            "& svg": {
                color: theme.palette.grey["500"],
            },
            "& ~ .description": {
                margin: 0,
                color: theme.palette.grey["800"],
                fontSize: theme.typography.caption.fontSize,
                paddingBottom: theme.spacingTheme["5"],
            },
        }),
    },
};
